import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Suspense } from 'react';
import route from './helper/route';
import './assets/css/fonts.css';
import './assets/css/style.css';

function App() {
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<div></div>}>
          <Routes>
            {route?.map((route, index) => {
              return (
                <Route
                  key={index}
                  exact
                  path={route.path}
                  element={route.element}
                />
              );
            })}
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
