import React from 'react'
import { Link } from "react-router-dom";
function Footer({scrollToTop}) {

    return (
        <div>
                    <div className="play-game-footer">
          <div className="container">
            <h3>Play games at Vasundhara Games</h3>
            <p>
              VasundharaGames features the latest and best free online games.
              You can enjoy playing fun games without interruptions from
              downloads, intrusive ads, or pop-ups. Just load up your favorite
              games instantly in your web browser and enjoy the experience.
            </p>
            <p>
              You can play our games on desktop and on mobile devices. That
              includes everything from desktop PCs, laptops, and Chromebooks, to
              the latest smartphones and tablets from Apple and Android.
            </p>
            <Link to="/">
          <button className="footer-btn">All Games</button>
        </Link>
          </div>
        </div>
        </div>
    )
}

export default Footer
