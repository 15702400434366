import React, { useState } from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Header from "../layout/header";
import '../assets/css/contact.css';
import '../assets/css/style.css';
import api from '../helper/api.js'
import { getServerURL } from "../helper/envConfig";
import HelmetContent from "../components/Helmet.jsx";
import Footer from "../layout/Footer.jsx";
const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    interest: "",
    budget: "",
  });

  const [formStatus, setFormStatus] = useState("");
  const serverURL = getServerURL();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.email && !formData.phoneNumber) {
      setFormStatus("Please provide at least an email or phone number.");
      return;
    }
  
    try {
      const response = await api.post(
        `${serverURL}send-email`,
        formData
      );
      const successMessage =
        typeof response?.data?.message === "string"
          ? response?.data?.message
          : "Your message has been sent successfully! We will get back to you shortly.";
  
      setFormStatus(successMessage);
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        interest: "",
        budget: "",
      });
    } catch (error) {
      console.error("Error sending message:", error.response || error.message);
      setFormStatus(
        "Oops! Something went wrong while sending your message. Please try again later."
      );
    }
  };
  
  const statusStyle = formStatus.includes("successfully")
    ? {
        color: "green",
        fontSize: "16px",
        marginTop: "10px",
        fontWeight: "bold",
      }
    : { color: "red", fontSize: "16px", marginTop: "10px", fontWeight: "bold" };

  return (
    <>
      <HelmetContent/>
      <Header />
      <section className="contact-section">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-7 px-0">
              <img
                src="/images/png/vasundhara-building.webp"
                className="img-fluid w-100"
                alt="vasundhara-building"
              />
            </div>
            <div className="col-lg-5 px-0">
              <div className="contact-form">
                <h2>Contact Us</h2>
                <Form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-sm-6">
                      <Form.Control
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        value={formData.firstName}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-sm-6">
                      <Form.Control
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        value={formData.lastName}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <Form.Control
                    type="number"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                  />
                  <Form.Select
                    name="interest"
                    value={formData.interest}
                    onChange={handleChange}
                  >
                    <option value="">I am interested in</option>
                    <option value="Web Development">Game Development</option>
                    <option value="App Development">2D/3D Animation</option>
                    <option value="Game Development">2D/3D Art</option>
                    <option value="Game Development">
                      Website Development
                    </option>
                    <option value="Game Development">App Development</option>
                    <option value="Game Development">Other</option>
                  </Form.Select>
                  <Form.Select
                    name="budget"
                    value={formData.budget}
                    onChange={handleChange}
                  >
                    <option value="">
                    What do you need from us?
                    </option>
                    <option value="Advise">Advise</option>
                    <option value="Develop from Scratch">Develop from Scratch</option>
                    <option value="Support & Maintenance">Support & Maintenance</option>
                    <option value="Optimize">Optimize</option>
                  </Form.Select>
                  <p>
                    This form collects your details to add you to our monthly
                    newsletter list. We treat your data with the utmost security
                    and will never sell it to third parties. Read our{" "}
                    <Link to="/">privacy policy</Link> for more.
                  </p>
                  <div className="d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary">
                      Contact Us
                    
                    </button>
                  </div>
                  {formStatus && <p style={statusStyle}>{formStatus}</p>}{" "}
              
                </Form>
              </div>
            </div>
          </div>
        </div>
        
      </section>
      <div className="contact-footer">
      <Footer />
      </div>
    </>
  );
};

export default Contact;
