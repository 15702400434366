import { useParams } from "react-router-dom";
import Header from "../layout/header";
import { Col, Row } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getServerURL } from "../helper/envConfig";
import api from "../helper/api.js";
import HelmetContent from "../components/Helmet.jsx";
import { PacmanLoader } from "react-spinners";
const serverURL = getServerURL();
const ViewMore = () => {
  const { categoryName } = useParams();
  const [games, setGames] = useState([]);
  const [category, setCategoryName] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    if (categoryName) {
      fetchGameDetails(categoryName.replace(/_/g, " "));
    }
  }, []);
  const saveGameToLocalStorage = (game) => {
    const existingGames =
      JSON.parse(localStorage.getItem("recentlyPlayed")) || [];
    const updatedGames = existingGames.filter((g) => g._id !== game._id);
    updatedGames.unshift(game);
    localStorage.setItem("recentlyPlayed", JSON.stringify(updatedGames));
  };

  const fetchGameDetails = async (name) => {
    try {
      // setLoading(true);
      const response = await api.get(
        `${serverURL}get_all_category_game?categoryName=${name}`
      );
      const data = await response.data;
      setGames(data.games);
      setCategoryName(data.categoryName);
    } catch (error) {
      console.error("Error fetching game details:", error);
    }
    // finally {
    //   setTimeout(() => {
    //     setLoading(false);
    //   }, 1000);
    //   // Step 2: Set loading to false after fetching
    // }
  };

  return (
    <>
      <HelmetContent/>
      <Header />
      <section className="vasu-game-one">
      {
        <div className="container">
          <div className="title-group">
            <h4>{category}</h4> {/* Replace hyphens with spaces */}
          </div>
          <Row className="game-slider g-sm-4 g-3">
            {games.map((game) => (
              <Col lg={3} sm={4} xs={6} key={game._id}>
                <div className="swiper-slide">
                  <Link
                    to={
                      game.orientation_landscape === true
                        ? `/game/l/${game.gameName
                            .replace(/\s+/g, "_")
                            .toLowerCase()}`
                        : `/game/p/${game.gameName
                            .replace(/\s+/g, "_")
                            .toLowerCase()}`
                    }
                    onClick={() => saveGameToLocalStorage(game)}
                  >
                    <img
                      src={game.square_banner}
                      className="img-fluid"
                      alt={game.gameName}
                    />
                    <div className="game-name">
                      <h5>{game.gameName}</h5>
                    </div>
                  </Link>
                </div>
              </Col>
            ))}
          </Row>
          <Pagination className="view-pagination">
            <Pagination.Prev />
            <Pagination.Item active>{1}</Pagination.Item>
            <Pagination.Item>{2}</Pagination.Item>
            <Pagination.Item>{3}</Pagination.Item>
            <Pagination.Item>{4}</Pagination.Item>
            <Pagination.Next />
          </Pagination>
        </div>
        }
    
        <div className="play-game-footer">
          <div className="container">
            <h3>Play games at Vasundhara Games</h3>
            <p>
              VasundharaGames features the latest and best free online games.
              You can enjoy playing fun games without interruptions from
              downloads, intrusive ads, or pop-ups. Just load up your favorite
              games instantly in your web browser and enjoy the experience.
            </p>
            <p>
              You can play our games on desktop and on mobile devices. That
              includes everything from desktop PCs, laptops, and Chromebooks, to
              the latest smartphones and tablets from Apple and Android.
            </p>
            <Link to="/">
              <button className="footer-btn">All Games</button>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default ViewMore;
