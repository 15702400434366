import { Navigate } from "react-router-dom";
import Home from "../pages/home";
import GameDetail from "../pages/game-detail";
import GameDetailVertical from "../pages/game-detail-vertical";
import ViewMore from "../pages/view-more";
import PrivacyPolicy from "../pages/privacy-policy";
import Contact from "../pages/contact"

const Route = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/game-detail",
    element: <GameDetail />,
  },
  {
    path: "/game/l/:gameName",
    element: <GameDetail />,
    key: (props) => props.gameName,
  },
  {
    path: "/game/p/:gameName",
    element: <GameDetailVertical />,
    key: (props) => props.gameName,
  },
  {
    path: "/game-detail-vertical",
    element: <GameDetailVertical />,
  },
  {
    path: "/view-more/:categoryName",
    element: <ViewMore />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/contact",
    element:  <Contact />,
},
  // {
  //   path: "/sitemap.xml",
  //   element: <sitmap />,
  // },
  // {
  //   path: "/sitemap-index.xml",
  //   element: <PrivacyPolicy />,
  // },
  // {
  //   path: "/sitemap-image.xml",
  //   element: <PrivacyPolicy />,
  // },
  {
    path: "*",
    element: <Navigate to="/" />,
  },
];

export default Route;
