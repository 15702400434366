export const toggleFullScreen = (isFullScreen) => {
    const isIOS =
      /iPhone|iPad|iPod/i.test(navigator.userAgent) && !window.MSStream;
  
    if (isIOS) {
      const element = document.documentElement;
      element.classList.toggle("fullscreen-mode", !isFullScreen);
    } else {
      if (!document.fullscreenElement && !isFullScreen) {
        document.documentElement.requestFullscreen();
      } else if (document.exitFullscreen && document.fullscreenElement) {
        document.exitFullscreen();
      }
    }
  };
  