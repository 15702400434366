import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import { Pagination } from "swiper/modules";
import { getServerURL } from "../helper/envConfig";
import api from "../helper/api.js";
import HelmetContent from "../components/Helmet.jsx";
import { PacmanLoader } from "react-spinners";
const Home = () => {
  const [gameData, setGameData] = useState([]);
  const [recentlyPlayedGames, setRecentlyPlayedGames] = useState([]);
  const [trandingGames, setTrandingGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const serverURL = getServerURL();
  const saveGameToLocalStorage = (game) => {
    const existingGames =
      JSON.parse(localStorage.getItem("recentlyPlayed")) || [];
    const updatedGames = existingGames.filter((g) => g._id !== game._id);
    updatedGames.unshift(game);
    localStorage.setItem("recentlyPlayed", JSON.stringify(updatedGames));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    fetchGameData();
    const existingGames =
      JSON.parse(localStorage.getItem("recentlyPlayed")) || [];
    setRecentlyPlayedGames(existingGames);
  }, []);

  const fetchGameData = async () => {
    try {
      // setLoading(true); // Step 2: Set loading to true before fetching
      const response = await api.get(`${serverURL}get_categories_wise_game`);
      setGameData(response.data.sortedCategories);
      setTrandingGames(response.data.trandingGames);
    } catch (error) {
      console.error("Error fetching game data:", error);
    }  
    // finally {
    //   setTimeout(() => {
    //     setLoading(false);
    //   }, 1000);
    //   // Step 2: Set loading to false after fetching
    // }
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  
  return (
    <>
      <HelmetContent/>
      <Header />
      <section className="vasu-game-one">
        <div className="container">
          <div className="recently-slider">
            {recentlyPlayedGames.length > 0 ? (
              <>
                <h4>Recently Played</h4>
                <Swiper
                  spaceBetween={10}
                  slidesPerView={8}
                  loop={true}
                  autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay]}
                  breakpoints={{
                    0: {
                      slidesPerView: 4,
                      spaceBetween: 5,
                    },
                    576: {
                      slidesPerView: 4,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 6,
                      spaceBetween: 10,
                    },
                    992: {
                      slidesPerView: 8,
                      spaceBetween: 10,
                    },
                  }}
                >
                  {recentlyPlayedGames.map((game) => (
                    <SwiperSlide key={game._id}>
                      <Link
                        to={
                          game.orientation_landscape === true
                            ? `/game/l/${encodeURIComponent(
                                game.gameName.replace(/\s+/g, "_").toLowerCase()
                              )}`
                            : `/game/p/${encodeURIComponent(
                                game.gameName.replace(/\s+/g, "_").toLowerCase()
                              )}`
                        }
                        state={{ game }}
                        onClick={() => saveGameToLocalStorage(game)}
                      >
                        <img
                          src={game.rectangle_banner}
                          className="img-fluid"
                          alt={game.gameName}
                        />
                      </Link>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </>
            ) : (
              ""
            )}
          </div>

          {/* Trending game list -1  */}
          <div className="trending-slider">
            <Swiper
              spaceBetween={22}
              slidesPerView={2}
              pagination={{
                clickable: true,
              }}
              loop={true}
              autoplay={{
                delay: 6000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, Pagination]}
              onSlideChange={() => {}}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                576: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 22,
                },
              }}
            >
              {trandingGames.map((game, index) => (
                <SwiperSlide key={game.gameId._id || index}>
                  <Link
                    to={
                      game.gameId.orientation_landscape
                        ? `game/l/${game.gameId.gameName
                            .replace(/\s+/g, "_")
                            .toLowerCase()}`
                        : `game/p/${game.gameId.gameName
                            .replace(/\s+/g, "_")
                            .toLowerCase()}`
                    }
                    state={{ game: game.gameId }}
                    onClick={() => saveGameToLocalStorage(game.gameId)}
                  >
                    <div className="trending-content">
                      <img
                        src={game.gameId.rectangle_banner}
                        className="img-fluid w-100"
                        alt={game.gameId.gameName}
                      />
                      <div className="trending-game">
                        <div className="d-flex align-items-center gap-3">
                          <img
                            src={game.gameId.appicon_image}
                            className="profile-img img-fluid"
                            alt="profile"
                          />
                          <div>
                            <h6>{game.gameId.gameName}</h6>
                            <div className="d-flex align-items-center gap-2">
                              <img
                                src="/images/png/action-icon.png"
                                className="action-icon img-fluid"
                                alt="icon"
                              />
                              <span>{game.game_category}</span>
                            </div>
                          </div>
                        </div>
                        <div className="play-btn">
                          <img
                            src="/images/svg/play.svg"
                            className="img-fluid"
                            alt="icon"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="trending-ribbon">
                      <img
                        src="/images/png/ribbon-red.png"
                        className="img-fluid"
                        alt="ribbon"
                      />
                      <span>Featured</span>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          {/* Category wise game list */}
         {
         
          gameData.map((category) => {
            if (category.games.length === 0) return null;
            return (
              <div className="game-slider" key={category._id}>
                <div className="title-group">
                  <h4>{category.categoryName}</h4>
                  <Link
                    to={`/view-more/${category.categoryName
                      .replace(/\s+/g, "_")
                      .toLowerCase()}`}
                  >
                    View more
                  </Link>
                </div>
                <Swiper
                  spaceBetween={17}
                  slidesPerView={4}
                  loop={true}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay]}
                  onSlideChange={() => {}}
                  breakpoints={{
                    0: {
                      slidesPerView: 2,
                      spaceBetween: 8,
                    },
                    480: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 17,
                    },
                    992: {
                      slidesPerView: 4,
                      spaceBetween: 17,
                    },
                  }}
                >
                  {category.games.map((game) => (
                    <SwiperSlide key={game._id}>
                      {" "}
                      <Link
                        to={
                          game.orientation_landscape === true
                            ? `game/l/${game.gameName
                                .replace(/\s+/g, "_")
                                .toLowerCase()}`
                            : `game/p/${game.gameName
                                .replace(/\s+/g, "_")
                                .toLowerCase()}`
                        }
                        state={{ game }}
                        onClick={() => saveGameToLocalStorage(game)}
                      >
                        <img
                          src={game.square_banner}
                          className="img-fluid"
                          alt={game.gameName}
                        />
                        <div className="game-name">
                          <h5>{game.gameName}</h5>
                        </div>
                      </Link>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            );
          })

         }

          {/* Trending game list -2 */}
          <div className="trending-slider">
            <Swiper
              spaceBetween={22}
              slidesPerView={2}
              pagination={{
                clickable: true,
              }}
              loop={true}
              autoplay={{
                delay: 6000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, Pagination]}
              onSlideChange={() => {}}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                576: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 22,
                },
              }}
            >
              {trandingGames.map((game, index) => (
                <SwiperSlide key={game.gameId._id || index}>
                  <Link
                    to={
                      game.gameId.orientation_landscape
                        ? `game/l/${game.gameId.gameName
                            .replace(/\s+/g, "_")
                            .toLowerCase()}`
                        : `game/p/${game.gameId.gameName
                            .replace(/\s+/g, "_")
                            .toLowerCase()}`
                    }
                    state={{ game: game.gameId }}
                    onClick={() => saveGameToLocalStorage(game.gameId)}
                  >
                    <div className="trending-content">
                      <img
                        src={game.gameId.rectangle_banner}
                        className="img-fluid w-100"
                        alt={game.gameId.gameName}
                      />
                      <div className="trending-game">
                        <div className="d-flex align-items-center gap-3">
                          <img
                            src={game.gameId.appicon_image}
                            className="profile-img img-fluid"
                            alt="profile"
                          />
                          <div>
                            <h6>{game.gameId.gameName}</h6>
                            <div className="d-flex align-items-center gap-2">
                              <img
                                src="/images/png/action-icon.png"
                                className="action-icon img-fluid"
                                alt="icon"
                              />
                              <span>{game.game_category}</span>
                            </div>
                          </div>
                        </div>
                        <div className="play-btn">
                          <img
                            src="/images/svg/play.svg"
                            className="img-fluid"
                            alt="icon"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="trending-ribbon">
                      <img
                        src="/images/png/ribbon-red.png"
                        className="img-fluid"
                        alt="ribbon"
                      />
                      <span>Featured</span>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>

        <div className="play-game-footer">
          <div className="container">
            <h3>Play games at Vasundhara Games</h3>
            <p>
              VasundharaGames features the latest and best free online games.
              You can enjoy playing fun games without interruptions from
              downloads, intrusive ads, or pop-ups. Just load up your favorite
              games instantly in your web browser and enjoy the experience.
            </p>
            <p>
              You can play our games on desktop and on mobile devices. That
              includes everything from desktop PCs, laptops, and Chromebooks, to
              the latest smartphones and tablets from Apple and Android.
            </p>
            <Link to="/" onClick={scrollToTop}>
          <button className="footer-btn">All Games</button>
        </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
