import React from "react";
import Header from "../layout/header";
import { Link } from "react-router-dom";
import HelmetContent from "./Helmet";
const PrivacyPolicyContent=()=>
{
    return(<>
    <HelmetContent/>
      <Header />
      <section className="privacy-policy">
        <div className="container">
          <h4>
            Privacy Policy for V Games (
            <Link to="https://vonlinegames.com" target="_blank">
              vonlinegames.com
            </Link>
            )
          </h4>
          <p>
            <strong>Effective Date: </strong>October 02, 2024
          </p>
          <p>
            This Privacy Policy describes how V Games ("we," "us," or "our")
            collects, uses, and discloses your information when you use our
            website, vonlinegames.com (the "Website").
          </p>
          <h6>Information We Collect</h6>
          <p>We collect two types of information on the Website:</p>
          <ul>
            <li>
              <strong>Non-Personally Identifiable Information (NPII): </strong>
              This information does not identify you personally. It may include
              information such as your browser type, device type, operating
              system, IP address, browsing activity on the Website, and game
              performance data. We collect this information through various
              technologies, including cookies and Google Analytics.
            </li>
            <li>
              <strong>Personally Identifiable Information (PII): </strong> This
              information can identify you as an individual. On our Website, PII
              may include age and username you provide solely for playing
              specific games. We do not collect any other PII.
            </li>
          </ul>
          <h6>Use of Information</h6>
          <p>We use the information we collect for the following purposes:</p>
          <ul>
            <li>
              <strong>To operate and improve the Website: </strong>We use NPII
              to understand how users interact with the Website and to improve
              its performance and user experience.
            </li>
            <li>
              <strong>To personalize your experience: </strong>We may use NPII
              to tailor the content and advertisements you see on the Website to
              your interests.
            </li>
            <li>
              <strong>To track game performance: </strong>We use NPII to track
              game performance data through Google Analytics and Firebase
              Analytics to identify and resolve any issues.
            </li>
            <li>
              <strong>To process future in-game purchases: </strong>If
              implemented in the future, we will use age verification and
              usernames to process in-game purchases securely.
            </li>
          </ul>
          <h6>Information Storage and Sharing</h6>
          <ul>
            <li>
              <strong>Local Storage: </strong>All user data and game status are
              stored locally on the user's device or browser. We do not store
              this information on our servers.
            </li>
            <li>
              <strong>Third-Party Services: </strong>We use Google Analytics and
              Firebase Analytics to track game performance. We may also use
              other third-party ad services like Adsense and AdsChoice. These
              services may collect their own NPII to deliver targeted
              advertising. Please refer to their respective privacy policies for
              more information.
            </li>
            <li>
              <strong>
                We will not share your PII with any third party without your
                consent.
              </strong>
            </li>
          </ul>
          <h6>Cookies and Similar Technologies</h6>
          <p>
            We may use cookies and similar technologies to collect information
            about your browsing activity on the Website. You can control how
            cookies are used by your browser settings. Disabling cookies may
            limit your ability to use certain features of the Website.
          </p>
          <h6>Security</h6>
          <p>
            We take reasonable steps to protect your information from
            unauthorized access, disclosure, alteration, or destruction.
            However, no website is 100% secure.
          </p>
          <h6>Children's Privacy</h6>
          <p>
            Our Website is intended for a general audience. We do not knowingly
            collect personal information from children under the age of 13. If
            you are a parent or guardian and you believe your child has provided
            us with PII, please contact us. We will delete such information from
            our records.
          </p>
          <h6>Your Choices</h6>
          <ul>
            <li>
              You can control how cookies are used by your browser settings.
            </li>
            <li>
              You can opt-out of Google Analytics by visiting{" "}
              <Link
                to="https://tools.google.com/dlpage/gaoptout"
                target="_blank"
              >
                https://tools.google.com/dlpage/gaoptout
              </Link>
              .
            </li>
            <li>
              You can learn more about online advertising and your choices by
              visiting the Network Advertising Initiative website (
              <Link to="https://thenai.org/" target="_blank">
                https://thenai.org/
              </Link>
              ) and the Digital Advertising Alliance website (
              <Link
                to="https://digitaladvertisingalliance.org/"
                target="_blank"
              >
                https://digitaladvertisingalliance.org/
              </Link>
              ).
            </li>
          </ul>
          <h6>Changes to this Privacy Policy</h6>
          <p>
            We may update this Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on the Website.
            You are advised to review this Privacy Policy periodically for any
            changes.
          </p>
          <h6>Contact Us</h6>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us at:
          </p>
          <p>games.vasundhara@gmail.com</p>
        </div>
      </section>
    </>)
}
export default PrivacyPolicyContent;