import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
const HelmetContent=()=>
{
    const location=useLocation();
    const getTitle=()=>
    {
        switch(location.pathname)
        {
            case "/":
                return "Vasundhara Game Studio | Home"
            case "/contact":
                return "Vasundhara Game Studio | Contact Us"
            case "/about":
                return "Vasundhara Game Studio | About Us"
            case "/view-more/simulation":
                return "Vasundhara Game Studio | Home"
            case "/privacy-policy":
                return "Vasundhara Game Studio | Privacy & Policy"
            default :
                return "Vasundhara Game Studio"

        }
    }
    const pageTitle = getTitle();
    return(<>
         <Helmet>
        <title>{pageTitle}</title>

        <meta name="title" content={pageTitle} />
        <meta
          name="keywords"
          content="Online Games, Free Games, Play Games Online, New Games, Best Games, Fun Games, Multiplayer Games, Action Games, Adventure Games, Puzzle Games, Strategy Games, Sports Games, Racing Games, Arcade Games, Mobile Games, PC Games, Online Gaming, Game Development, Gaming Community, Free Online Gaming, Free online multiplayer action games, Best puzzle games for mobile, New strategy games for PC, Online racing games for kids, Free online adventure games for adults, 
Word Search, Word Connect, Serve Burger, Puzzle Food Game, Bubble Shooter, Bubble Games, Trivia Puzzle, Hexa Puzzle, Fit It 3D, Max Hexa Pipe, Tangle Master, Classify, Sudoku Offline, Classic Puzzles, Oneline, Water Sort Puzzle, Block Puzzle, Cute Animal Puzzle, Monster Merge, Ball Sort, Match 3, Math Game, Redhands, Color Wheel, Pipeout, Drawgame, Merge Dice, The Pig Escape, Peter Rise Up, Save The Ball, Emball, Color Ball Rush Legend, Minimal Shooting, In The Air, Flappy Bird, Fishing 2D, Falling Ball, Color Tunnel, Umbrella Down, Helix Fruit, Cube Stack, Dash Valley, Strike The Can, Ballhop, Droppy Tower, Car Racing IO, Color Race, Baby Phone For Kids, ABC Alphabet, Shoe Maker, Color Drawing, Bottle Shooting Vs Gun, Blast Shooting, Color Shooter, Arrow And Bow, Zombie Defense 2, Vellys Of Cubes, Angry Cube, Tap Tap Rush, Sky Rise, Side Jump, Cubejump, Uno, Clash Masters, Jellyshift, Line Worker, Solitaire, Fruit Juice Maker, Make It Nine, 2D Tower Defence, Droppy Tower, Car Wash Mania, Crowd Ghost City, Real Estate Tycoon, Baby Doll House Cleaning, Deep Sea Fishing, Idle Restaurant, Pizza Maker, Space Frontier, Survivor Mode, Neon Space, Jumping Ninja, Infinite Brick Breaker, Kong Hero, Gun Jump Simulator, Sky Raider, Bumperio, Bottle Tap, Mini Golf Masters, Crazy Basketball, Head Soccer, Trickshot Arena, Action Games, Adventure Games, Arcade Games, Puzzle Games, Strategy Games, Sports Games, Racing Games, Multiplayer Games, Online Games, Free Games, Mobile Games, PC Games, WebGL Games, HTML5 Games, Unity Games, Free online puzzle games, Best action games for mobile, New strategy games for PC, Online multiplayer racing games, Free online adventure games for kids, Best puzzle games for adults, Online sports games for mobile, Free online arcade games for PC, Multiplayer games for kids, Online games for adults"
        />
        <meta
          name="description"
          content="Play the best free online games at V Online Games! Explore our vast collection of action, adventure, puzzle, strategy, and sports games for mobile and PC. Play now and have fun!"
        />
        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content={pageTitle} />
        <meta property="og:image" content="/card.jpg" />
        <meta property="og:image:secure_url" content="/card.jpg" />
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:description"
          content="Play the best free online games at V Online Games! Explore our vast collection of action, adventure, puzzle, strategy, and sports games for mobile and PC. Play now and have fun!"
        />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:image" content="/card.jpg" />
        <meta property="twitter:url" content={window.location.href} />
        <meta name="publisher" content={pageTitle} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
    </>)
}
export default HelmetContent;