import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Header from "../layout/header";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { getServerURL } from "../helper/envConfig";
import api from "../helper/api.js";
import { isMobileDevice } from "../utils/device";
import { saveToLocalStorage } from "../utils/localStorage";
import { toggleFullScreen } from "../utils/fullScreen";
import Footer from "../layout/Footer.jsx";
import { PacmanLoader } from "react-spinners";
const GameDetailVertical = () => {
  const [isIframeVisible, setIframeVisible] = useState(false);
  const [isFullScreen, setFullScreen] = useState(false);
  const [gameDetails, setGameDetails] = useState({});
  const [rightSideGames, setRightSideGames] = useState([]);
  const [rankingGames, setRankingGames] = useState([]);
  const [isLiked, setIsLiked] = useState(false);
  const [loading, setLoading] = useState(true);
  const updateLocalStorage = (gameId, liked) => {
    const existingLikes = JSON.parse(localStorage.getItem("likedGames")) || [];

    if (liked) {
      // Add game to liked games
      if (!existingLikes.includes(gameId)) {
        existingLikes.push(gameId);
      }
    } else {
      // Remove game from liked games
      const updatedLikes = existingLikes.filter((id) => id !== gameId);
      localStorage.setItem("likedGames", JSON.stringify(updatedLikes));
      return;
    }

    localStorage.setItem("likedGames", JSON.stringify(existingLikes));
  };

  const checkIfLiked = (gameId) => {
    const likedGames = JSON.parse(localStorage.getItem("likedGames")) || [];
    return likedGames.includes(gameId);
  };
  const handleLikeToggle = () => {
    const newLikedStatus = !isLiked;
    setIsLiked(newLikedStatus);
    updateLocalStorage(gameDetails._id, newLikedStatus);
  };

  // Fetch liked status on component mount
  useEffect(() => {
    const likedStatus = checkIfLiked(gameDetails._id);
    setIsLiked(likedStatus);
  }, [gameDetails]);

  const { gameName } = useParams();
  const serverURL = getServerURL();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePlayNowClick = () => {
    if (isMobileDevice()) {
      toggleFullScreen(isFullScreen);
    }

    setIframeVisible(true);
    if (window.innerWidth < 992) {
      setFullScreen(true);
    }
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const gameThumb = document.querySelector(".game-thumb");
    if (gameThumb) {
      if (isFullScreen) {
        gameThumb.classList.add("full-screen");
      } else {
        gameThumb.classList.remove("full-screen");
      }
    }
  }, [isFullScreen]);

  const handleFullScreenToggle = () => {
    if (isMobileDevice()) {
      toggleFullScreen(isFullScreen);
    } else {
      if (!isFullScreen) {
        toggleFullScreen(isFullScreen);
      } else {
        toggleFullScreen(isFullScreen);
      }
    }

    if (isIframeVisible && window.innerWidth < 992) {
      setIframeVisible(false);
    }

    setFullScreen((prevState) => !prevState);
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (gameName) {
      fetchGameDetails(gameName.replace(/_/g, " "));
    }
  }, [gameName]);
  const [gameURL,setGameURL]=useState('')
const slugify = (text) => {
  return text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '_')
    .replace(/[^\w_]+/g, '')
    .replace(/_+/g, '_')
    .replace(/^_+|_+$/g, '');
};

  const fetchGameDetails = async (name) => {
    // Step 1: Try to get game data from local storage first
    const cachedGame = getGameFromLocalStorage(name);
  
    if (cachedGame) {
      // If game is cached, use it instead of calling API
      setGameDetails(cachedGame);
      setLoading(false); // Set loading false because we already have the data
    } 
    else {
      try {
        setLoading(true);
        const response = await api.get(
          `${serverURL}/get_game?gameName=${encodeURIComponent(name)}`
        );
        const data = response.data;
  
        setGameDetails(data.games);
        setRightSideGames(data.showGames);
        setRankingGames(data.rankingGames);
        
        // Step 2: Save the fetched game data to local storage
        saveGameToLocalStorage(data.games);
        if (data.games && data.games.gameId) {
          const gameNameSlug = slugify(data.games.gameName);
          const gameURL = `http://localhost:3000/game/p/${gameNameSlug}`;
          setGameURL(gameURL); 
          saveGameToLocalStorage(data.games);
        }
      } catch (error) {
        console.error("Error fetching game details:", error);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    }
  };
const getGameFromLocalStorage = (name) => {
  const existingGames = JSON.parse(localStorage.getItem("recentlyPlayed")) || [];

  // Safeguard: Ensure that the game has a valid 'name' property before using toLowerCase()
  return existingGames.find((g) => g.name && g.name.toLowerCase() === name.toLowerCase());
};

  
  const saveGameToLocalStorage = (game) => {
    const existingGames =
      JSON.parse(localStorage.getItem("recentlyPlayed")) || [];
  
    // Step 3: Ensure no duplicate games, and only keep the latest 10 games
    const updatedGames = existingGames.filter((g) => g._id !== game._id);
    updatedGames.unshift(game); // Add the new game to the top
  
    // Limit the stored games to the last 10
    const gamesToStore = updatedGames.slice(0, 10);
  
    saveToLocalStorage("recentlyPlayed", gamesToStore);
  };
  
  const saveToLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };
  const handleShare = async () => {

    const shareData = {
      title: 'Vasundhara Games',
      text: 'Check out this awesome game!',
      url: gameURL,
    };
  
    if (navigator.share) {
      
      try {
        await navigator.share(shareData);
        console.log('Share successful!');
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
     
      alert('Web Share API not supported. Copy this link: ' + shareData.url);
    }
  };
  return (
    <>
      {gameDetails && (
        <Helmet htmlAttributes={{ "data-react-helmet": null }}>
          <title>
            {" "}
            Vasundhara Game Studio | {gameDetails.gameName ?? "Game"}{" "}
          </title>
          <meta
            name="title"
            content={`Vasundhara Game Studio | ${gameDetails.gameName}`}
          />
          <meta
            name="robots"
            content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          />
          <meta name="description" content={gameDetails.description} />
          <meta
            property="og:title"
            content={`Vasundhara Game Studio | ${gameDetails.gameName}`}
          />
          <meta property="og:description" content={gameDetails.description} />
          <meta
            property="og:site_name"
            content={`Vasundhara Game Studio | ${gameDetails.gameName}`}
          />
          <meta property="og:url" content={window.location.href}></meta>
          <meta property="og:image" content={gameDetails.square_banner} />
          <meta
            property="og:image:secure_url"
            content={gameDetails.square_banner}
          />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:image:width" content="500" />
          <meta property="og:image:height" content="500" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={gameDetails.gameName} />
          <meta name="twitter:description" content={gameDetails.description} />
          <meta name="twitter:image" content={gameDetails.square_banner} />
          <meta property="twitter:url" content={window.location.href} />

          <meta name="publisher" content={gameDetails.gameName} />
          <link rel="canonical" href={window.location.href} />
          {gameDetails.game_keywords && gameDetails.game_keywords[0] && (
            <meta
              name="keywords"
              content={gameDetails.game_keywords[0].split(",").join(", ")}
            />
          )}
        </Helmet>
      )}
      <Header />
      <section className="vasu-game-two">
        <div className="container">
          <Row className="justify-content-center">
            <Col xxl={4} xl={5} lg={6}>
            {
              loading ?
              <div className="loader-wrapper">
                <PacmanLoader color="#0192ED"/>
              </div>
              :
              <div
                className="game-thumb"
                style={{ backgroundImage: `url(${gameDetails.square_banner})` }}
              >
                {isIframeVisible && (
                  <>
                    <div className="iframe-container vertical-game-view">
                      <iframe
                        src={`${gameDetails.game_build}`}
                        title={gameDetails.gameName}
                      />
                    </div>
                    <div
                      className="close-iframe"
                      onClick={handleFullScreenToggle}
                    >
                      <img
                        src="/images/svg/exit-full-screen.svg"
                        class="img-fluid"
                        alt="icon"
                      />
                    </div>
                  </>
                )}
                <div className="d-lg-none thumb-res">
                  <img
                    src={gameDetails.square_banner}
                    className="img-fluid w-100"
                    alt="slider-image"
                  />
                </div>
                <div className="game-status">
                  <img
                    src={gameDetails.appicon_image}
                    className="img-fluid"
                    alt="slider-image"
                    style={{ borderRadius: "15px" }}
                  />
                  <h4>{gameDetails.gameName}</h4>
                  <div className="d-flex align-items-center gap-1 justify-content-center">
                    <img
                      src="/images/png/action-icon.png"
                      className="img-fluid"
                      alt="icon"
                    />
                    <span>Action</span>
                  </div>
                  <button className="btn play-now" onClick={handlePlayNowClick}>
                    <img
                      src="/images/png/play-button.png"
                      className="img-fluid"
                      alt="icon"
                    />
                    Play Now
                  </button>
                  <div className="d-flex align-items-center footer-gap icon-res d-lg-none">
                  <div
                      onClick={handleLikeToggle}
                      className={`like-button ${isLiked ? "liked" : ""}`}
                    >
                      {isLiked ? (
                        <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_345_7121" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="26" height="25">
<path d="M25.4036 0.195312H0.875V24.7239H25.4036V0.195312Z" fill="white"/>
</mask>
<g mask="url(#mask0_345_7121)">
<path d="M24.1496 8.08809C23.6703 7.53579 23.078 7.09291 22.4127 6.78945C21.7474 6.48599 21.0247 6.32903 20.2935 6.32919H16.2166L16.56 4.24323C16.6815 3.50866 16.535 2.75489 16.147 2.1194C15.7591 1.48391 15.1556 1.00905 14.4467 0.781427C13.7378 0.5538 12.9707 0.58858 12.2853 0.879422C11.5999 1.17026 11.0419 1.69778 10.713 2.36577L8.75684 6.32919H5.98511C4.63032 6.33081 3.33149 6.86971 2.37351 7.82769C1.41553 8.78567 0.876623 10.0845 0.875 11.4393V16.5494C0.876623 17.9042 1.41553 19.2031 2.37351 20.1611C3.33149 21.119 4.63032 21.6579 5.98511 21.6596H19.5781C20.808 21.6545 21.9953 21.2081 22.924 20.4016C23.8526 19.5951 24.4609 18.482 24.6381 17.2649L25.3586 12.1547C25.4601 11.4299 25.4047 10.6916 25.1961 9.99C24.9875 9.28839 24.6306 8.63979 24.1496 8.08809ZM2.91904 16.5494V11.4393C2.91904 10.6262 3.24207 9.84625 3.81707 9.27125C4.39207 8.69625 5.17194 8.37323 5.98511 8.37323H8.02916V19.6155H5.98511C5.17194 19.6155 4.39207 19.2925 3.81707 18.7175C3.24207 18.1425 2.91904 17.3626 2.91904 16.5494Z" fill="white"/>
</g>
</svg>

                      ) : (
                        <svg
                          width="26"
                          height="25"
                          viewBox="0 0 26 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_4208_74630)">
                            <path
                              d="M24.1476 8.08662C23.6683 7.53432 23.076 7.09145 22.4107 6.78799C21.7454 6.48453 21.0227 6.32756 20.2915 6.32772H16.2146L16.558 4.24177C16.6795 3.5072 16.533 2.75343 16.145 2.11794C15.7571 1.48245 15.1536 1.00759 14.4447 0.779962C13.7358 0.552335 12.9687 0.587115 12.2833 0.877957C11.5979 1.1688 11.0399 1.69632 10.711 2.36431L8.75489 6.32772H5.98316C4.62837 6.32934 3.32954 6.86825 2.37156 7.82623C1.41358 8.78421 0.87467 10.083 0.873047 11.4378L0.873047 16.5479C0.87467 17.9027 1.41358 19.2016 2.37156 20.1596C3.32954 21.1175 4.62837 21.6564 5.98316 21.6581H19.5761C20.806 21.653 21.9933 21.2066 22.922 20.4001C23.8506 19.5936 24.4589 18.4805 24.6361 17.2634L25.3566 12.1532C25.4581 11.4284 25.4027 10.6901 25.1941 9.98853C24.9855 9.28693 24.6286 8.63832 24.1476 8.08662ZM2.91709 16.5479V11.4378C2.91709 10.6247 3.24012 9.84479 3.81512 9.26979C4.39012 8.69479 5.16999 8.37176 5.98316 8.37176H8.02721V19.614H5.98316C5.16999 19.614 4.39012 19.291 3.81512 18.716C3.24012 18.141 2.91709 17.3611 2.91709 16.5479ZM23.3279 11.8661L22.6064 16.9762C22.5009 17.7059 22.137 18.3735 21.5809 18.8576C21.0248 19.3417 20.3134 19.6101 19.5761 19.614H10.0713V8.0999C10.1676 8.01599 10.2473 7.91477 10.3063 7.80147L12.5435 3.2688C12.6274 3.11747 12.746 2.98821 12.8896 2.89163C13.0332 2.79506 13.1976 2.73394 13.3694 2.71329C13.5412 2.69264 13.7155 2.71305 13.8778 2.77284C14.0402 2.83263 14.1861 2.9301 14.3035 3.05724C14.4039 3.174 14.4773 3.31149 14.5184 3.45989C14.5596 3.60828 14.5675 3.76394 14.5416 3.91574L14.002 7.18622C13.9783 7.33235 13.9866 7.48188 14.0264 7.62446C14.0662 7.76705 14.1365 7.89929 14.2324 8.01204C14.3284 8.12479 14.4476 8.21535 14.582 8.27746C14.7164 8.33957 14.8627 8.37175 15.0107 8.37176H20.2915C20.7303 8.3717 21.1641 8.46586 21.5634 8.64786C21.9628 8.82986 22.3184 9.09547 22.6063 9.42673C22.8941 9.75799 23.1075 10.1472 23.232 10.568C23.3565 10.9889 23.3892 11.4315 23.3279 11.8661Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_4208_74630">
                              <rect
                                width="24.5286"
                                height="24.5286"
                                fill="white"
                                transform="translate(0.873047 0.195312)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      )}
                      <span>{isLiked ? "Liked" : "Like"}</span>
                    </div>
                    <div onClick={handleShare} style={{ cursor: 'pointer' }}>
                      <img
                        src="/images/svg/share.svg"
                        className="img-fluid"
                        alt="icon"
                      />
                      <span>Share</span>
                    </div>
                  </div>
                </div>
                <div className="game-footer">
                  <div className="d-flex align-items-center footer-gap">
                    <img
                      src="/images/png/logo-small.png"
                      className="img-fluid vasu-logo"
                      alt="icon"
                    />
                    <h5>{gameDetails.gameName}</h5>
                  </div>
                  <div className="d-flex align-items-center footer-gap">
                    <div
                      onClick={handleLikeToggle}
                      className={`like-button ${isLiked ? "liked" : ""}`}
                    >
                      {isLiked ? (
                        <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_345_7121" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="26" height="25">
<path d="M25.4036 0.195312H0.875V24.7239H25.4036V0.195312Z" fill="white"/>
</mask>
<g mask="url(#mask0_345_7121)">
<path d="M24.1496 8.08809C23.6703 7.53579 23.078 7.09291 22.4127 6.78945C21.7474 6.48599 21.0247 6.32903 20.2935 6.32919H16.2166L16.56 4.24323C16.6815 3.50866 16.535 2.75489 16.147 2.1194C15.7591 1.48391 15.1556 1.00905 14.4467 0.781427C13.7378 0.5538 12.9707 0.58858 12.2853 0.879422C11.5999 1.17026 11.0419 1.69778 10.713 2.36577L8.75684 6.32919H5.98511C4.63032 6.33081 3.33149 6.86971 2.37351 7.82769C1.41553 8.78567 0.876623 10.0845 0.875 11.4393V16.5494C0.876623 17.9042 1.41553 19.2031 2.37351 20.1611C3.33149 21.119 4.63032 21.6579 5.98511 21.6596H19.5781C20.808 21.6545 21.9953 21.2081 22.924 20.4016C23.8526 19.5951 24.4609 18.482 24.6381 17.2649L25.3586 12.1547C25.4601 11.4299 25.4047 10.6916 25.1961 9.99C24.9875 9.28839 24.6306 8.63979 24.1496 8.08809ZM2.91904 16.5494V11.4393C2.91904 10.6262 3.24207 9.84625 3.81707 9.27125C4.39207 8.69625 5.17194 8.37323 5.98511 8.37323H8.02916V19.6155H5.98511C5.17194 19.6155 4.39207 19.2925 3.81707 18.7175C3.24207 18.1425 2.91904 17.3626 2.91904 16.5494Z" fill="white"/>
</g>
</svg>

                      ) : (
                        <svg
                          width="26"
                          height="25"
                          viewBox="0 0 26 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_4208_74630)">
                            <path
                              d="M24.1476 8.08662C23.6683 7.53432 23.076 7.09145 22.4107 6.78799C21.7454 6.48453 21.0227 6.32756 20.2915 6.32772H16.2146L16.558 4.24177C16.6795 3.5072 16.533 2.75343 16.145 2.11794C15.7571 1.48245 15.1536 1.00759 14.4447 0.779962C13.7358 0.552335 12.9687 0.587115 12.2833 0.877957C11.5979 1.1688 11.0399 1.69632 10.711 2.36431L8.75489 6.32772H5.98316C4.62837 6.32934 3.32954 6.86825 2.37156 7.82623C1.41358 8.78421 0.87467 10.083 0.873047 11.4378L0.873047 16.5479C0.87467 17.9027 1.41358 19.2016 2.37156 20.1596C3.32954 21.1175 4.62837 21.6564 5.98316 21.6581H19.5761C20.806 21.653 21.9933 21.2066 22.922 20.4001C23.8506 19.5936 24.4589 18.4805 24.6361 17.2634L25.3566 12.1532C25.4581 11.4284 25.4027 10.6901 25.1941 9.98853C24.9855 9.28693 24.6286 8.63832 24.1476 8.08662ZM2.91709 16.5479V11.4378C2.91709 10.6247 3.24012 9.84479 3.81512 9.26979C4.39012 8.69479 5.16999 8.37176 5.98316 8.37176H8.02721V19.614H5.98316C5.16999 19.614 4.39012 19.291 3.81512 18.716C3.24012 18.141 2.91709 17.3611 2.91709 16.5479ZM23.3279 11.8661L22.6064 16.9762C22.5009 17.7059 22.137 18.3735 21.5809 18.8576C21.0248 19.3417 20.3134 19.6101 19.5761 19.614H10.0713V8.0999C10.1676 8.01599 10.2473 7.91477 10.3063 7.80147L12.5435 3.2688C12.6274 3.11747 12.746 2.98821 12.8896 2.89163C13.0332 2.79506 13.1976 2.73394 13.3694 2.71329C13.5412 2.69264 13.7155 2.71305 13.8778 2.77284C14.0402 2.83263 14.1861 2.9301 14.3035 3.05724C14.4039 3.174 14.4773 3.31149 14.5184 3.45989C14.5596 3.60828 14.5675 3.76394 14.5416 3.91574L14.002 7.18622C13.9783 7.33235 13.9866 7.48188 14.0264 7.62446C14.0662 7.76705 14.1365 7.89929 14.2324 8.01204C14.3284 8.12479 14.4476 8.21535 14.582 8.27746C14.7164 8.33957 14.8627 8.37175 15.0107 8.37176H20.2915C20.7303 8.3717 21.1641 8.46586 21.5634 8.64786C21.9628 8.82986 22.3184 9.09547 22.6063 9.42673C22.8941 9.75799 23.1075 10.1472 23.232 10.568C23.3565 10.9889 23.3892 11.4315 23.3279 11.8661Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_4208_74630">
                              <rect
                                width="24.5286"
                                height="24.5286"
                                fill="white"
                                transform="translate(0.873047 0.195312)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      )}
                      <span>{isLiked ? "Liked" : "Like"}</span>
                    </div>
                    <div onClick={handleShare} style={{ cursor: 'pointer' }}>
                      <img
                        src="/images/svg/share.svg"
                        className="img-fluid"
                        alt="icon"
                      />
                      <span>Share</span>
                    </div>
                    <div
                      className={`full-icon ${
                        !isIframeVisible ? "disabled" : ""
                      }`}
                      onClick={handleFullScreenToggle}
                    >
                      <img
                        src="/images/svg/full-screen.svg"
                        className="img-fluid"
                        alt="icon"
                      />
                      <span>
                        {isFullScreen ? "Exit Screen" : "Full Screen"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            }
          
            </Col>
          </Row>
          <Row>
            <Col md={9} sm={8}>
              <Row className="g-sm-3 g-1">
                {rankingGames.map((game) => {
                  const gameNameSlug = game.gameName
                    .replace(/\s+/g, "_")
                    .toLowerCase();
                  const gamePath = game.orientation_landscape
                    ? `/game/l/${gameNameSlug}`
                    : `/game/p/${gameNameSlug}`;

                  return (
                    
                    <Col md={4} sm={6} className="col-6">
                      <div className="position-relative">
                        <div className="game-box">
                          <Link
                            to={gamePath}
                            onClick={() => saveGameToLocalStorage(game)}
                            key={game._id}
                          >
                            <img
                              src={game.square_banner}
                              className="img-fluid w-100"
                              alt={`Banner for ${game.gameName}`}
                            />
                            <div className="game-name">
                              <h5>{game.gameName}</h5>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
              <div className="game-details">
                <div className="game-profile">
                  <img
                    src={gameDetails.appicon_image}
                    className="img-fluid"
                    alt={`Banner for ${gameDetails.gameName}`}
                  />
                  <h6>{gameDetails.gameName}</h6>
                </div>
                <p>{gameDetails.description}</p>
                <div className="category-keywords mt-sm-5 mt-4">
                  <div className="d-flex align-items-center">
                    <h5>Category</h5>
                    <img
                      src="/images/svg/angle-double-right.svg"
                      className="img-fluid"
                      alt="icon"
                    />
                  </div>
                  {gameDetails.game_category && (
                    <span>{gameDetails.game_category.categoryName}</span>
                  )}
                </div>
                <div className="category-keywords">
                  <ul>
                    <li className="keyword-title d-flex align-items-center">
                      <h5>Keywords</h5>
                      <img
                        src="/images/svg/angle-double-right.svg"
                        className="img-fluid"
                        alt="icon"
                      />
                    </li>
                    {gameDetails &&
                    gameDetails.game_keywords &&
                    gameDetails.game_keywords.length > 0 ? (
                      gameDetails.game_keywords[0]
                        .split(",")
                        .map((keyword, index) => (
                          <li key={index}>{keyword.trim()}</li>
                        ))
                    ) : (
                      <li>No keywords available</li>
                    )}
                  </ul>
                </div>
                <div className="download-game">
                  <h4>Download & Play The Game</h4>
                  <div className="store-btn">
                    <Link to={gameDetails.PlayStore_link} className="btn" target="_blank" rel="noopener noreferrer">
                      <img
                        src="/images/png/play-store.png"
                        className="img-fluid"
                        alt="icon"
                      />
                      <div className="text-start">
                        <span>GET IT ON</span>
                        <h6>GOOGLE PLAY</h6>
                      </div>
                    </Link>
                    <Link to={gameDetails.appStore_link} className="btn" target="_blank" rel="noopener noreferrer">
                      <img
                        src="/images/png/app-store.png"
                        className="img-fluid"
                        alt="icon"
                      />
                      <div className="text-start">
                        <span>DOWNLOAD ON</span>
                        <h6>
                          <span>THE </span>APP STORE
                        </h6>
                      </div>
                    </Link>
                  </div>
                  <h5>Share the Game to your Friends</h5>
                </div>
              </div>
            </Col>
            <Col md={3} sm={4}>
              <div className="game-banner">
                {rightSideGames.map((game) => {
                  const gameNameSlug = game.gameName
                    .replace(/\s+/g, "_")
                    .toLowerCase();
                  const gamePath = game.orientation_landscape
                    ? `/game/l/${gameNameSlug}`
                    : `/game/p/${gameNameSlug}`;

                  return (
                    <Link
                      to={gamePath}
                      onClick={() => saveGameToLocalStorage(game)}
                      key={game._id}
                    >
                      <img
                        src={game.rectangle_banner}
                        className="img-fluid w-100"
                        alt={`Banner for ${game.gameName}`}
                      />
                    </Link>
                  );
                })}
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default GameDetailVertical;
