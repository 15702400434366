import React, { useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import Offcanvas from 'react-bootstrap/Offcanvas';

const Header = () => {

    const location = useLocation();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <header className='header-wrapper'>
                <div className='container'>
                    <div className='d-flex justify-content-between'>
                        <div className='left-menu'>
                            <Link to="/"><img src="/images/png/logo.png" className="logo-wrapper img-fluid" alt="logo" /></Link>
                            {/* <div className='search-group'>
                                <img src="/images/svg/search.svg" className='search-icon' onClick={toggleInputVisibility} alt="icon" />
                                {isInputVisible && (
                                    <div className='search-input'>
                                        <img src="/images/svg/search.svg" className='search-icon' onClick={toggleInputVisibility} alt="icon" />
                                        <input placeholder="Search" className="form-control" type="text" />
                                    </div>
                                )}
                            </div> */}
                        </div>
                        <div className='right-menu'>
                            <ul>
                                <li>
                                    <Link to="/" className={`${location?.pathname === "/" ? "active" : ""}`}>Home</Link>
                                </li>
                                <li>
                                    <Link to="/privacy-policy" className={`${location?.pathname === "/privacy-policy" ? "active" : ""}`}>Privacy & Policy</Link>
                                </li>
                            </ul>
                            <Link to="/contact" className="btn header-btn">Get In Touch</Link>
                            <img src="/images/svg/toggle-bar.svg" className='toggle-bar' alt="toggle-bar" onClick={handleShow} />
                        </div>
                    </div>
                </div>
                <Offcanvas show={show} onHide={handleClose} placement={'end'} className="mobile-sidebar">
                    <Offcanvas.Header closeButton>
                        <Link to="/">
                            <img src="/images/png/logo.png" className="logo-wrapper img-fluid" alt="logo" />
                        </Link>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <ul>
                            <li>
                                <Link to="/" className={`${location?.pathname === "/" ? "active" : ""}`}>Home</Link>
                            </li>
                            <li>
                                <Link to="/privacy-policy" className={`${location?.pathname === "/privacy-policy" ? "active" : ""}`}>Privacy & Policy</Link>
                            </li>
                        </ul>
                        <Link to="/contact" className="btn header-btn">Get In Touch</Link>
                    </Offcanvas.Body>
                </Offcanvas>
            </header>
        </>
    );
};

export default Header;
