import { React } from "react";
import PrivacyPolicyContent from "../components/privacy-policy-content";
import Footer from "../layout/Footer";
const PrivacyPolicy = () => {
  return (
    <>
      <PrivacyPolicyContent/>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
